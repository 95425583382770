import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticQuery, graphql } from "gatsby"

import HomeSection1 from "../../components/home/section-1"
import HomeSection2 from "../../components/home/section-2"
import HomeSection3 from "../../components/home/section-3"
import HomeSection4 from "../../components/home/section-4"
import HomeSection5 from "../../components/home/section-5"
import HomeSection6 from "../../components/home/section-6"
import HomeSection7 from "../../components/home/section-7"
import HomeSection8 from "../../components/home/section-8"
import HomeSection9 from "../../components/home/section-9"

const IndexPage = ({ contentField }) => {
  const lang = "de"
  const seo = contentField.seo
  const image = contentField.featuredImage

  return (
    <Layout
      lang={lang}
      translationEN="/"
      translationES="/es/"
      translationIT="/it/"
      translationFR="/fr/"
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />
      <HomeSection1 lang={lang} />
      <HomeSection2 lang={lang} />
      <HomeSection3 lang={lang} />
      <span className="anchor" id="exceptional-taste"></span>
      <HomeSection4 lang={lang} />
      <HomeSection5 lang={lang} />
      <HomeSection6 lang={lang} />
      <HomeSection7 lang={lang} />
      <HomeSection8 lang={lang} />
      <HomeSection9 lang={lang} />
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDoxMzcy" }) {
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <IndexPage {...data} />}
  />
)
